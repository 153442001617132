import { Component, ENVIRONMENT_INITIALIZER, HostListener, Inject, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_BROADCAST_CONFIG, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionRequiredAuthError, InteractionStatus, PopupRequest } from '@azure/msal-browser';
import { filter } from 'rxjs/operators';
import { AppError } from '../common/class/app-error';
import { ExternalTokenModel } from '../Model/ExternalTokenModel';
import { IExternalTokenResponseDetails, ResultContent } from '../Model/ExternalTokenRespone';
import { TokenModel } from '../Model/TokenModel';
import { RpostAuthenticationService } from '../service/rpost-authentication.service';
import { DataSharingService } from '../service/data-sharing.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AzureTokenResponseModel } from '../Model/AzureTokenResponseModel';
import { RefreshTokenResponseModel } from '../Model/RefreshTokenResponseModel';
import { AzureIdTokenResponseModel } from '../Model/AzureIdTokenResponseModel';
import { ErrorReason } from '../Model/ErrorReasonModel';
import { environment } from 'src/environments/environment';
import { AzureTokenResponseService } from '../service/azure-token-response.service';
import { AzureAccessTokenResponseModel } from '../Model/AzureAccessTokenResponseModel';
import { AzureIdTokenClaimsResponseModel } from '../Model/AzureIdTokenClaimsResponseModel';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { param } from 'jquery';
import { MSALInstanceFactory, MSALInterceptorConfigFactory } from '../app.module';
import { GoogleAuthenticationService } from '../service/google-authentication.service';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from "@abacritt/angularx-social-login";
import { GoogleTokenResponseModel } from '../Model/Google/GoogleAccountDetailsModel';

declare var $: any;  
@Component({
  selector: 'app-rpost-authentication',
  templateUrl: './rpost-authentication.component.html',
  styleUrls: ['./rpost-authentication.component.css'],
  providers: [DataSharingService]
})
export class RPostAuthenticationComponent implements OnInit {
  title = 'RPostAuthUI';
  isIframe = false;
  loginDisplay = false;
  providerAccessToken='';
  refreshTokenFromRCAPAPI='';
  accessTokenFromRCAPAPI='';
  azureTokenResponseGv='';
  public  resultContentObj=new ResultContent();
  safeSrc: SafeResourceUrl;
  clientAppLoginUrl='';
  callBackUrlFromQueryString='';
  clientIdFromQueryString='';
  loginUrlFromQueryString='';
  isUnAuthorized='';
  showLoginPopup='';
  googleRefreshToken='';
  googleSSOClientId=environment.googleSSOClientId;
  
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ,private rpostAuthService: RpostAuthenticationService
  ,private authService: MsalService
  , private msalBroadcastService: MsalBroadcastService
  ,private dataShareService:DataSharingService
  ,private auzureTokenRespService:AzureTokenResponseService
  ,private sanitizer: DomSanitizer
  , private route: ActivatedRoute
  ,private googleAutheService:GoogleAuthenticationService
  ,private socialAuthService: SocialAuthService) {
    let clientId= localStorage.getItem("clienIdLS")!;
    let callbackUrl= localStorage.getItem("callbackUrl_"+clientId)!;
    this.clientAppLoginUrl = localStorage.getItem("loginUrl_"+clientId)!;
    this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(callbackUrl);
    //this.route.params.subscribe( params => this.clientAppLoginUrl=params['LoginUrl']);
    this.route.queryParams.subscribe(params => {
      this.callBackUrlFromQueryString = params['CallBackUrl'];
      this.clientIdFromQueryString= params['RpostClientId'];
      this.loginUrlFromQueryString=params['LoginUrl'];
      this.isUnAuthorized=params['IsUnAuthorized'];
      this.showLoginPopup=params['ShowLoginPopup'];
      this.clientAppLoginUrl=(this.clientAppLoginUrl==null && this.loginUrlFromQueryString!=null)
                            ?this.loginUrlFromQueryString:this.clientAppLoginUrl;
      clientId=(clientId==null && this.clientIdFromQueryString!=null)?this.clientIdFromQueryString:clientId;
     });
   }


   disableGoogleButton(callbackUrl:string)
   {
    if(callbackUrl!=null && callbackUrl.indexOf("rmail.")!=-1)
        $("#pnlGoogleBtn").addClass("disabledbutton");

   }
   displaySpinner()
   {
    
    $('#pnlSpinner').css("display","block");
   }

   hideSpinner()
   {
    $('#pnlSpinner').css("display","none");
   }
   displaySSOButtons()
   {
    //alert('display')
    $('#pnlSSOButtons').css("display","block");
   }
   hideSSOButtons()
   {
    //alert('hide');
    //$('#pnlSSOButtons').css("display","none");
    $('#pnlSSOButtons').attr('style', 'display: none !important');
   }
   /* postMessageIfUserAlreadyLoggedin(clientId:string,callbackUrl:string)
   {
    
    postMessageToClientApp(clientId,callbackUrl,azuretokenResp);
   } */
   
googleBtnOnMouseOver()
{
 alert('hi');
 $("#googleBtn").attr('title', 'Sign in with Google');
 //document.getElementById('googleBtn')?.setAttribute('title', 'Sign in with Google');
}
   ngOnInit(): void {
    $('#divProcessingCommon').css("display","none");
    //this.disableGoogleButton(this.callBackUrlFromQueryString); //this is temp fix only
    this.displaySSOButtons(); 
    
    this.getGoogleTokenResponseAndRedirectToClientApplication();
    
  }
 
  generateIframeHtmlElement()
  {
          this.hidePnlComingSoon();
          let clientId= this.getRpostClientId(); 
          
          let callbackUrl=this.getCallBackUrl(); 
          this.clearLocalStorageTokenResponse();
          this.generateDataShareIframeHtmlElement(callbackUrl,clientId);
  }
  
    
  
//#region  GoogleSociallogin functionality

 

  getGoogleTokenResponseAndRedirectToClientApplication()
  {
    this.generateIframeHtmlElement();
    this.getGoogleAccountDetails();
    
  }
  
  getGoogleAccountDetails()
  {
    let userDetails: any;
    this.socialAuthService.authState.subscribe((user) => {
    //this.user = user;
    userDetails=user;
      console.log(userDetails);
      if(userDetails!=null)
      {
        let googleTokenResponseModelObj=this.prepareGoogleTokenResponseObject(userDetails);
        let googleTokenResponse=this.getGoogleTokenResponse(googleTokenResponseModelObj);
        if(googleTokenResponse!=null && googleTokenResponse!='')
        {
          this.redirectToClientApplication(googleTokenResponse);
          //this.googleAutheService.getAccessToken();
          //alert(this.googleAutheService.refreshToken());
        }
      }
      //alert(this.user.authToken);
      //alert(userDetails.email);
      //this.loggedIn = (user != null);
    },
    error => {
      let errorDesc=error;
      this.displayActualErrorInConsole(errorDesc);
      this.redirectToClientAppLoginWhenErrorOccured(ErrorReason.GOOGLE_LOGIN_FAILED);
    }
    );

    //return userDetails;
  }

  getGoogleTokenResponse(googleTokenRespDetails:GoogleTokenResponseModel )
  {
    //todo: to be implemented as similar to azure token response
    return this.convertObjToJsonString(googleTokenRespDetails);
    
  }

  convertObjToJsonString(obj:any)
  {
    let response;
    response= JSON.stringify(obj);
    return response;
  }

  getGoogleRefreshToken()
  {
    /* return this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID)
    .then(refreshToken => this.googleRefreshToken = refreshToken);
 */
  }

  prepareGoogleTokenResponseObject(userDetails:SocialUser)
  {
    let googleTokenRespObj=new GoogleTokenResponseModel();
    googleTokenRespObj.accesstoken=userDetails.idToken;
    googleTokenRespObj.idtoken=userDetails.idToken;
    googleTokenRespObj.refreshtoken= ''; // this.getGoogleRefreshToken();
    googleTokenRespObj.useremail=userDetails.email;
    googleTokenRespObj.username=userDetails.name;
    googleTokenRespObj.ssotype=environment.googleProvider;
    return googleTokenRespObj;
  }

  
//#endregion
  
  CancelCurrentRequest()
  {
    window.location.href=this.loginUrlFromQueryString; //+'?errorReason='+ErrorReason.USER_CANCELLED_CURRENT_REQUEST;

  }

   AcquireTokenSilentAndRedirectClientAppIfAlreadyLogggedIn()
   {
     
      if(this.isUserAlreadyLoggedin())
      {
        //alert('You have already logged in using Azure Sign in');
        this.hideSSOButtons();
        let clientId= this.getRpostClientId(); 
        
        let callbackUrl= this.getCallBackUrl(); 
        this.clearLocalStorageTokenResponse();
        console.log("Before iframe creation");
        this.generateDataShareIframeHtmlElement(callbackUrl,clientId);
        console.log("After iframe creation");
            let scopeId=environment.scopeId
            //acquire token sliently article
            //https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/4b74fda49768168768ed427609941566e41456d3/lib/msal-browser/docs/token-lifetimes.md#token-renewal
            //user already loggin scenario
                //alert('already logged in');
                var request = {
                  //scopes: ['user.read'] //["Mail.Read"]
                  //scopes: ["api://8f491afb-add0-4cd4-82a5-f9e477d6a057/.default"]
                  scopes: ["api://"+scopeId+"/.default"],
                  prompt:'select_account'
              };
          
              this.authService.acquireTokenSilent(request)
                                  .subscribe((result: AuthenticationResult) => {
                  

                  console.log(result);
                  const payload = result as AuthenticationResult;
                  
                  this.authService.instance.setActiveAccount(payload.account);
                  
                  let azureTokenRespObj= this.getAzureTokenResponseObjectForAquireTokenSilent(payload);//this.getAzureTokenResponseObject(payload);
                  let azuretokenresp= this.getAzureTokenJsonResponse(azureTokenRespObj);
                  //this.azureTokenResponseGv=azuretokenresp;
                  if(azuretokenresp!=null && azuretokenresp!="")
                      this.redirectToClientApplication(azuretokenresp);
                  else
                  {
                    let errorReason= ErrorReason.AZURE_TOKEN_EMPTY_RESPONSE_ACQUIRE_TOKEN_SILENT; //"Azure token response is empty for acquireTokenSilent Method";
                    this.redirectToClientAppLoginWhenErrorOccured(errorReason);
                  }
                  
                  
              }
              ,
            error => {
              let errorDesc=error;
              this.displayActualErrorInConsole(errorDesc);
               //AcquireTokenSilent Failure, send an interactive request.
                //alert('eeror')
               this.authService.acquireTokenPopup(request)
                                  .subscribe((result: AuthenticationResult) => {
                 

                  console.log(result);
                  const payload = result as AuthenticationResult;
                  
                  this.authService.instance.setActiveAccount(payload.account);
                  
                  let azureTokenRespObj= this.getAzureTokenResponseObjectForAquireTokenSilent(payload);//this.getAzureTokenResponseObject(payload);
                  let azuretokenresp= this.getAzureTokenJsonResponse(azureTokenRespObj);
                  //this.azureTokenResponseGv=azuretokenresp;
                  if(azuretokenresp!=null && azuretokenresp!="")
                      this.redirectToClientApplication(azuretokenresp);
                  else
                  {
                    let errorReason= ErrorReason.AZURE_TOKEN_EMPTY_RESPONSE_ACQUIRE_TOKEN_POPUP; //"Azure token response is empty from acquireTokenPopup method";
                    this.redirectToClientAppLoginWhenErrorOccured(errorReason);
                  }
                  //this.redirectToClientApplication();
                  
                  
              }
              ,
              error => {
                
                  let errorDesc=error;
                  this.displayActualErrorInConsole(errorDesc);
                  let errorReason= ErrorReason.ACQUIRE_TOKEN_POPUP_FAILURE;
                  this.redirectToClientAppLoginWhenErrorOccured(errorReason);
                
              },
              

              );
              
               
            }/* ,
            () => {
              // 'onCompleted' callback.
              // No errors, route to new page here
            } */
              
              );
              
              this.msalBroadcastService.msalSubject$
              .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
              )
              .subscribe((result: EventMessage) => {
                let errorReason="ACQUIRE_TOKEN_FAILURE";
                
                this.loginPopup();
              });
          
              this.msalBroadcastService.inProgress$
              .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None)
              )
              .subscribe(() => {
                this.setLoginDisplay();
              })
            
              

        }
   }

   
   async AzureSignIn()
   {
          /* this.hidePnlComingSoon();
          let clientId= this.getRpostClientId(); 
          
          let callbackUrl=this.getCallBackUrl(); 
          this.clearLocalStorageTokenResponse();
          this.generateDataShareIframeHtmlElement(callbackUrl,clientId); */
          this.generateIframeHtmlElement();
          //this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
          //alert('this.isIframe'+this.isIframe);
          //alert(location.hash);

        if(!this.isUserAlreadyLoggedin())
        {
          console.log("first time login");
          
          await this.firstTimeLoginAndDisplayLoginPopup();
          
        }
        else if(this.isUserAlreadyLoggedin() && 
         (this.isUnAuthorized!=null && this.isUnAuthorized=='Y') 
         ||(this.showLoginPopup!=null && this.showLoginPopup=='Y')
        )
        {
          console.log('Already logged in, unauthorized request/unable to login due to some reason case, show loginpopup');
          //this.promptForPasswordForUnAuthroizedRequest();
          this.chooseAccountToLoginForAlreadyLoggedIn(true);
        } 
        else if(this.isUserAlreadyLoggedin())
        {
          console.log("AlreadyLoggedIn");
          //this.AcquireTokenSilentAndRedirectClientAppIfAlreadyLogggedIn();
          this.chooseAccountToLoginForAlreadyLoggedIn(false);
        }
    }

  async firstTimeLoginAndDisplayLoginPopup()
  {
          await this.loginPopup();
          this.hideSSOButtons();
          await this.msalBroadcastService.msalSubject$
            .pipe(
              filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
            )
            .subscribe((result: EventMessage) => {
              console.log(result);
              const payload = result.payload as AuthenticationResult;
              
              this.authService.instance.setActiveAccount(payload.account);
              
              let azureTokenRespObj=this.getAzureTokenResponseObject(payload);
              let azuretokenresp= this.getAzureTokenJsonResponse(azureTokenRespObj);
              localStorage.setItem("azureTokenRespLocalStorage",azuretokenresp);
              
                if(azuretokenresp!=null && azuretokenresp!="")
                      this.redirectToClientApplication(azuretokenresp);
                  else
                  {
                    let errorReason= ErrorReason.AZURE_TOKEN_EMPTY_RESPONSE_FIRST_TIME_LOGIN; //"Azure token response is empty for First time azure login";
                    this.redirectToClientAppLoginWhenErrorOccured(errorReason);
                  }
            }
            ,
            error => {
               
              
              var errorDesc=error.toString();
              let errorReason=''
             
              if(typeof errorDesc == "string" && errorDesc.indexOf(ErrorReason.CONSENT_REQUIRED) > -1)
              {
                errorReason= ErrorReason.CONSENT_REQUIRED;
                this.redirectToClientAppLoginWhenErrorOccured(errorReason);
              }
              else
              {
                this.displayActualErrorInConsole(errorDesc);
                errorReason=ErrorReason.UNKNOWN_ERROR_OCCURED;
                this.redirectToClientAppLoginWhenErrorOccured(errorReason);
              }
            },
            );
          
          await this.msalBroadcastService.inProgress$
            .pipe(
              filter((status: InteractionStatus) => status === InteractionStatus.None)
            )
            .subscribe(() => {
              this.setLoginDisplay();
            })
          
            this.msalBroadcastService.msalSubject$
            .pipe(
              filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
            )
            .subscribe((result: EventMessage) => {
              
              if(localStorage.getItem("IsPopupClosedFromRCAP")==null)
              {
                let errorReason=  ErrorReason.AZURE_LOGIN_FAILURE; //"AZURE_LOGIN_FAILURE";
                this.redirectToClientAppLoginWhenErrorOccured(errorReason);
              }
              else if(localStorage.getItem("IsPopupClosedFromRCAP")!=null 
              && localStorage.getItem("IsPopupClosedFromRCAP")=='Y')
              {
                  localStorage.removeItem("IsPopupClosedFromRCAP");
                  let azuretokenresp=null;
                  if(localStorage.getItem("azureTokenRespLocalStorage")!=null)
                      azuretokenresp=localStorage.getItem("azureTokenRespLocalStorage");
                  if(azuretokenresp!=null && azuretokenresp!="")
                      this.redirectToClientApplication(azuretokenresp);
                  else
                  {
                    let errorReason= ErrorReason.AZURE_TOKEN_EMPTY_RESPONSE_FIRST_TIME_LOGIN;//"Azure token response is empty for First time azure login";
                    this.redirectToClientAppLoginWhenErrorOccured(errorReason);
                  }
                  //window.location.href=this.callBackUrlFromQueryString;
              }
                
            });

  }
  
  chooseAccountToLoginForAlreadyLoggedIn(IsUnAuthorized:boolean)
  {
    this.hideSSOButtons();
    if(IsUnAuthorized)
        console.log("already logged in, but unauthorized, autologin");
    else
    console.log("already logged in");

    let scopeId=environment.scopeId
            
    var request = {
      scopes: ["api://"+scopeId+"/.default"],
      prompt:'select_account'
    };
  this.authService.loginPopup(request)
                          .subscribe((result: AuthenticationResult) => {
          

          console.log(result);
          const payload = result as AuthenticationResult;
          
          this.authService.instance.setActiveAccount(payload.account);
          
          let azureTokenRespObj= this.getAzureTokenResponseObjectForAquireTokenSilent(payload);//this.getAzureTokenResponseObject(payload);
          let azuretokenresp= this.getAzureTokenJsonResponse(azureTokenRespObj);
          //this.azureTokenResponseGv=azuretokenresp;
          if(azuretokenresp!=null && azuretokenresp!="")
              this.redirectToClientApplication(azuretokenresp);
          else
          {
            let errorReason= ErrorReason.AZURE_TOKEN_EMPTY_RESPONSE_ACQUIRE_TOKEN_POPUP;//"Azure token response is empty from acquireTokenPopup method";
            this.redirectToClientAppLoginWhenErrorOccured(errorReason);
          }
          //this.redirectToClientApplication();
          
          
      }
      ,
      
      error => {
       
        //if(IsUnAuthorized)
        //{
          var errorDesc=error.toString();
          let errorReason=''
          //alert(typeof errorDesc);
          if(typeof errorDesc == "string" && errorDesc.indexOf(ErrorReason.USER_CANCELLED) > -1)
          {
            errorReason= ErrorReason.USER_CANCELLED_FROM_UNAUTHORIZED_AT_LOGIN_POPUP;
            this.redirectToClientAppLoginWhenErrorOccured(errorReason);
            //this.acquireTokenSilientImpl(request);
          }
          else
          {
            this.displayActualErrorInConsole(errorDesc);
            errorReason=ErrorReason.UNKNOWN_ERROR_OCCURED;
            this.redirectToClientAppLoginWhenErrorOccured(errorReason);
          }
          
        //}
       /*  else
        {
          this.authService.acquireTokenSilent(request)
                                  .subscribe((result: AuthenticationResult) => {
                  //alert('acquire token silent');
                  //alert(result.accessToken);

                  console.log(result);
                  const payload = result as AuthenticationResult;
                  
                  this.authService.instance.setActiveAccount(payload.account);
                  
                  let azureTokenRespObj= this.getAzureTokenResponseObjectForAquireTokenSilent(payload);//this.getAzureTokenResponseObject(payload);
                  let azuretokenresp= this.getAzureTokenJsonResponse(azureTokenRespObj);
                  //this.azureTokenResponseGv=azuretokenresp;
                  if(azuretokenresp!=null && azuretokenresp!="")
                      this.redirectToClientApplication(azuretokenresp);
                  else
                  {
                    let errorReason= ErrorReason.AZURE_TOKEN_EMPTY_RESPONSE_ACQUIRE_TOKEN_SILENT; //"Azure token response is empty for acquireTokenSilent Method";
                    this.redirectToClientAppLoginWhenErrorOccured(errorReason);
                  }
                  
                  
              }
              ,
            error => {
              let errorDesc=error;
              this.displayActualErrorInConsole(errorDesc);
               //AcquireTokenSilent Failure, send an interactive request.
                //alert('eeror')
               this.authService.acquireTokenPopup(request)
                                  .subscribe((result: AuthenticationResult) => {
                  //alert('acquire token silent');
                  //alert(result.accessToken);

                  console.log(result);
                  const payload = result as AuthenticationResult;
                  
                  this.authService.instance.setActiveAccount(payload.account);
                  
                  let azureTokenRespObj= this.getAzureTokenResponseObjectForAquireTokenSilent(payload);//this.getAzureTokenResponseObject(payload);
                  let azuretokenresp= this.getAzureTokenJsonResponse(azureTokenRespObj);
                  //this.azureTokenResponseGv=azuretokenresp;
                  if(azuretokenresp!=null && azuretokenresp!="")
                      this.redirectToClientApplication(azuretokenresp);
                  else
                  {
                    let errorReason= ErrorReason.AZURE_TOKEN_EMPTY_RESPONSE_ACQUIRE_TOKEN_POPUP; //"Azure token response is empty from acquireTokenPopup method";
                    this.redirectToClientAppLoginWhenErrorOccured(errorReason);
                  }
                  //this.redirectToClientApplication();
                  
                  
              }
              ,
              error => {
                
                  let errorDesc=error;
                  this.displayActualErrorInConsole(errorDesc);
                  let errorReason= ErrorReason.ACQUIRE_TOKEN_POPUP_FAILURE;
                  this.redirectToClientAppLoginWhenErrorOccured(errorReason);
                
              },
              

              );
              
               
            }
              
              );

        } */
        
      },
      );

  }

  //acquire token token siliently when any error occured during login popup  account selection/ for any idle time scenario
  acquireTokenSilientImpl(request:any)
  {
    console.log("acquireTokenSilientImpl");
    this.authService.acquireTokenSilent(request)
                                  .subscribe((result: AuthenticationResult) => {
                  

                  console.log(result);
                  const payload = result as AuthenticationResult;
                  
                  this.authService.instance.setActiveAccount(payload.account);
                  
                  let azureTokenRespObj= this.getAzureTokenResponseObjectForAquireTokenSilent(payload);//this.getAzureTokenResponseObject(payload);
                  let azuretokenresp= this.getAzureTokenJsonResponse(azureTokenRespObj);
                  //this.azureTokenResponseGv=azuretokenresp;
                  if(azuretokenresp!=null && azuretokenresp!="")
                      this.redirectToClientApplication(azuretokenresp);
                  else
                  {
                    let errorReason= ErrorReason.AZURE_TOKEN_EMPTY_RESPONSE_ACQUIRE_TOKEN_SILENT; //"Azure token response is empty for acquireTokenSilent Method";
                    this.redirectToClientAppLoginWhenErrorOccured(errorReason);
                  }
                  
                  
              }
              ,
            error => {
              console.log("error at acquireTokenSilientImpl method call");
              let errorDesc=error;
              this.displayActualErrorInConsole(errorDesc);
               //AcquireTokenSilent Failure, send an interactive request.
                //alert('eeror')
               this.authService.acquireTokenPopup(request)
                                  .subscribe((result: AuthenticationResult) => {
                  

                  console.log(result);
                  const payload = result as AuthenticationResult;
                  
                  this.authService.instance.setActiveAccount(payload.account);
                  
                  let azureTokenRespObj= this.getAzureTokenResponseObjectForAquireTokenSilent(payload);//this.getAzureTokenResponseObject(payload);
                  let azuretokenresp= this.getAzureTokenJsonResponse(azureTokenRespObj);
                  //this.azureTokenResponseGv=azuretokenresp;
                  if(azuretokenresp!=null && azuretokenresp!="")
                      this.redirectToClientApplication(azuretokenresp);
                  else
                  {
                    let errorReason= ErrorReason.AZURE_TOKEN_EMPTY_RESPONSE_ACQUIRE_TOKEN_POPUP; //"Azure token response is empty from acquireTokenPopup method";
                    this.redirectToClientAppLoginWhenErrorOccured(errorReason);
                  }
                  //this.redirectToClientApplication();
                  
                  
              }
              ,
              error => {
                console.log("error at acquireTokenPopup method call");
                  let errorDesc=error;
                  this.displayActualErrorInConsole(errorDesc);
                  let errorReason= ErrorReason.ACQUIRE_TOKEN_POPUP_FAILURE;
                  this.redirectToClientAppLoginWhenErrorOccured(errorReason);
                
              },
              

              );
              
               
            }
              
              );

  }

  /* promptForPasswordForUnAuthroizedRequest()
  {
    this.hideSSOButtons();
          console.log("already logged in, but unauthorized, autologin");
          let scopeId=environment.scopeId
            
            var request = {
              //scopes: ['user.read'] //["Mail.Read"]
              //scopes: ["api://8f491afb-add0-4cd4-82a5-f9e477d6a057/.default"]
              scopes: ["api://"+scopeId+"/.default"],
              //loginHint:'',
              prompt:'select_account'
          };
          this.authService.loginPopup(request)
                                  .subscribe((result: AuthenticationResult) => {
                  //alert('acquire token silent');
                  //alert(result.accessToken);

                  console.log(result);
                  const payload = result as AuthenticationResult;
                  
                  this.authService.instance.setActiveAccount(payload.account);
                  
                  let azureTokenRespObj= this.getAzureTokenResponseObjectForAquireTokenSilent(payload);//this.getAzureTokenResponseObject(payload);
                  let azuretokenresp= this.getAzureTokenJsonResponse(azureTokenRespObj);
                  //this.azureTokenResponseGv=azuretokenresp;
                  if(azuretokenresp!=null && azuretokenresp!="")
                      this.redirectToClientApplication(azuretokenresp);
                  else
                  {
                    let errorReason= ErrorReason.AZURE_TOKEN_EMPTY_RESPONSE_ACQUIRE_TOKEN_POPUP;//"Azure token response is empty from acquireTokenPopup method";
                    this.redirectToClientAppLoginWhenErrorOccured(errorReason);
                  }
                  //this.redirectToClientApplication();
                  
                  
              }
              ,
              
              error => {
               
                //alert(error);
                var errorDesc=error.toString();
                let errorReason=''
                //alert(typeof errorDesc);
                if(typeof errorDesc == "string" && errorDesc.indexOf(ErrorReason.USER_CANCELLED) > -1)
                {
                  //errorReason= ErrorReason.USER_CANCELLED_FROM_UNAUTHORIZED_AT_LOGIN_POPUP;
                  //this.redirectToClientAppLoginWhenErrorOccured(errorReason);
                  this.acquireTokenSilientImpl(request);
                }
                else
                {
                  this.displayActualErrorInConsole(errorDesc);
                  errorReason=ErrorReason.UNKNOWN_ERROR_OCCURED;
                  this.redirectToClientAppLoginWhenErrorOccured(errorReason);
                }
              },
              );

              
  } */

  getAzureTokenResponseObject(payload:AuthenticationResult)
  {
          let refreshtokenObj=<RefreshTokenResponseModel>this.auzureTokenRespService.getRefreshTokenJsonFromLocalStorage()
          let azureTokenRespObj=new AzureTokenResponseModel();
          azureTokenRespObj.accesstoken= payload.accessToken;//payload.idToken;
          azureTokenRespObj.useremail=payload.account?.username!;
          azureTokenRespObj.username=payload.account?.name!;
          azureTokenRespObj.idtoken=payload.idToken;
          azureTokenRespObj.refreshtoken=refreshtokenObj.secret; 
          azureTokenRespObj.ssotype=environment.azureADProvider;
          return azureTokenRespObj;
  }

  getAzureTokenResponseObjectForAquireTokenSilent(payload:AuthenticationResult)
  {
          /* let refreshtokenObj=<RefreshTokenResponseModel>this.auzureTokenRespService.getRefreshTokenJsonFromLocalStorage()
          let azureTokenRespObj=new AzureTokenResponseModel();
          azureTokenRespObj.accesstoken= payload.accessToken;
          azureTokenRespObj.useremail=payload.account?.username!;
          azureTokenRespObj.username=payload.account?.name!;
          azureTokenRespObj.idtoken=payload.idToken;
          azureTokenRespObj.refreshtoken=refreshtokenObj.secret; 
          azureTokenRespObj.ssotype=environment.azureADProvider;
          return azureTokenRespObj; */
          return this.getAzureTokenResponseObject(payload);
  }

  /* getAzureTokenResponseObjectForAlreadySingleSingON()
  {
          let refreshtokenObj=<RefreshTokenResponseModel>this.auzureTokenRespService.getRefreshTokenJsonFromLocalStorage()
          let idTokenObj=<AzureIdTokenResponseModel>this.auzureTokenRespService.getIdTokenJsonFromLocalStorage();
          let accessTokenObj=<AzureAccessTokenResponseModel>this.auzureTokenRespService.getAccessTokenJsonFromLocalStorage();
          let idTokenClaimsObj=<AzureIdTokenClaimsResponseModel>this.auzureTokenRespService.getIdTokenCliamsJsonFromLocalStorage();
          let azureTokenRespObj=new AzureTokenResponseModel();
          azureTokenRespObj.accesstoken= accessTokenObj.secret; //idTokenObj.secret;//accessTokenObj.secret;
          azureTokenRespObj.useremail=idTokenClaimsObj.username;
          azureTokenRespObj.username=idTokenClaimsObj.name;
          azureTokenRespObj.idtoken=idTokenObj.secret;
          azureTokenRespObj.refreshtoken=refreshtokenObj.secret; 
          azureTokenRespObj.ssotype=environment.azureADProvider;
          return azureTokenRespObj;
  } */

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if(this.loginDisplay)
      $('#btnLogout').css('display','block');
    
  }

  checkAndSetActiveAccount(){
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  async loginPopup() {
    let scopeId= environment.scopeId;
    var request = {
      scopes: ["api://"+scopeId+"/.default"],
      prompt:'select_account'
    };
    
    if (this.msalGuardConfig.authRequest){
      /* await this.authService.loginPopup({...this.msalGuardConfig.authRequest} as PopupRequest) */
      await this.authService.loginPopup(request)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        },
        error => {
          
          var errorDesc=error.toString();
          let errorReason=''
          this.displayActualErrorInConsole(errorDesc);
          if(typeof errorDesc == "string" && errorDesc.indexOf(ErrorReason.USER_CANCELLED) > -1)
          {
            errorReason= ErrorReason.USER_CANCELLED;
            
          }
          else
          {
            errorReason= ErrorReason.MSAL_GUARD_CONFIG_AUTH_REQUEST_LOGIN_POPUP_FAILURE;
            
          }
          //this.redirectToClientAppLoginWhenErrorOccured(errorReason);
          
        },
        );
      } else {
        await this.authService.loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
      },
      error => {
            let errorDesc=error;
            this.displayActualErrorInConsole(errorDesc);
            let errorReason= ErrorReason.LOGIN_POPUP_FAILURE;
            this.redirectToClientAppLoginWhenErrorOccured(errorReason);
          
        },
      );
      
    }
    
  }

displayPnlComingSoon()
{
  $('#pnlComingSoon').css("display","block");
  setTimeout(function () {
    $('#pnlComingSoon').css("display","none");
  }, 2000);
  
}

hidePnlComingSoon()
{
  $('#pnlComingSoon').css("display","none");
}

redirectToClientAppLoginWhenErrorOccured(errorDesc:string)
{
  console.log("error is  " + errorDesc);
  this.clearLocalStorage();
  window.location.href= this.clientAppLoginUrl +"?errorReason="+errorDesc;
}

displayActualErrorInConsole(errorDesc:string)
{
  console.log('actual error is');
  console.log(errorDesc);
}

  logout(popup?: boolean) {
    let returnUrl=window.location.href; 
    let clientId= this.getRpostClientId();
    
    let callbackUrl= this.getCallBackUrl(); 
    
    if (popup) {
      this.authService.logoutPopup({
        
        mainWindowRedirectUri:returnUrl
       
      });
    } else {
      this.authService.logoutRedirect();
    }
  }
  /* callRCAPExternalTokenAPI(providerAccessToken:string)
  {
    
    var externalTokenModelObj=new ExternalTokenModel();
    var clientId=localStorage.getItem("clienIdLS")!;
    
    if(clientId==null)
    {
      alert(clientId);
      return;
    }
    externalTokenModelObj.Client_Id=clientId;
    externalTokenModelObj.provider='AzureAD'; //To DO: Need to Remove hardcoded value
    externalTokenModelObj.provider_access_token=providerAccessToken;

    this.rpostAuthService.getExternalTokenAPIResponseToken(externalTokenModelObj)
    .subscribe(async resp => {
      
        const response = resp as IExternalTokenResponseDetails;
        localStorage.setItem("extTokenAPIResponse_"+clientId,resp.toString()); 
        let callbackUrl= this.getCallBackUrl();//localStorage.getItem("callbackUrl_"+clientId)!;
        this.postLocalStorageData(callbackUrl, 'trackingportal');
        window.location.href = callbackUrl;
         
  });
  } */

   generateDataShareIframeHtmlElement(callbackUrl:string,rpostClientId:string)
   {
    //removeIframeElement(rpostClientId);
    const iframe = document.createElement('IFRAME');
    iframe.id = 'receiverSOS1_'+rpostClientId;
    //iframe.id = 'receiverIframe';
    iframe.style.display = "none";
    iframe.style.width="1px";
    //iframe.sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin"
    (<HTMLIFrameElement>iframe).src = callbackUrl; 
    document.body.appendChild(iframe);
    
   }

   postLocalStorageData(linkURL: any, portal: any) {
     
    this.dataShareService.postCrossDomainMessage(linkURL, portal);
  }

  isUserAlreadyLoggedin()
  {
    let isLoggedIn = this.authService.instance.getAllAccounts().length > 0;
    /* if(isLoggedIn)
      this.setAzureTokenResponseFromCookie(); */
    return isLoggedIn;
  }

 /*  setAzureTokenResponseIntoLocalStorage(clientId:string,azureTokenResp:string)
  {
    localStorage.setItem("azuretokenresp_"+clientId,azureTokenResp);
  } */
  /* setAzureTokenResponseFromCookie()
  {
    let clientId=this.getRpostClientId();
    let azureTokenRespFromCookie=this.getCookie(clientId);
    let azureTokenResponseFromLocalStorage=localStorage.getItem("azuretokenresp_"+ this.getAzureClientId());
    if((azureTokenResponseFromLocalStorage==null
      || azureTokenResponseFromLocalStorage ==undefined)
      &&(azureTokenRespFromCookie!=null && azureTokenRespFromCookie!=undefined)
      )
      {
       
        //localStorage.setItem("azuretokenresp_"+ clientId,azureTokenRespFromCookie); 
        this.setAzureTokenResponseIntoLocalStorage(clientId,azureTokenRespFromCookie);
      }
  }
 */
  getRpostClientId()
  {
    
    return this.clientIdFromQueryString;
  }

  getCallBackUrl()
  {
    

    return this.callBackUrlFromQueryString;
  }

  getAzureClientId()
  {
    return environment.azureSSOClientId;
  }
  getAzureTokenJsonResponse(azureTokenRespObj:AzureTokenResponseModel)
  {
    let response;
    response= JSON.stringify(azureTokenRespObj);
   

    return response;
  }
  clearLocalStorageTokenResponse()
  {
        let clientId= this.getRpostClientId();
        
        localStorage.removeItem("azuretokenresp_"+this.getAzureClientId());
  }

  redirectToClientApplication(azureTokenResponse:string)
  {
        
      this.onLoad(azureTokenResponse);
     //this.dataShareService.postCrossDomainMessage("test");
  }
  

 onLoad(azureTokenResponse:string) {
        let clientId= this.getRpostClientId(); 
        let callbackUrl= this.getCallBackUrl();
        //alert(callbackUrl);
        let azuretokenResp= azureTokenResponse; 
        
        const date = new Date();
        
        
        console.log('is window:load');
        var iframe = document.getElementById("receiverSOS1_"+clientId); 
        //const iframe = document.getElementById("receiverIframe");
        console.log(iframe);
        //alert(iframe)
        //if (iframe == null) { return; }
        if (iframe == null) { 
          this.generateDataShareIframeHtmlElement(callbackUrl,clientId);
          iframe = document.getElementById("receiverSOS1_"+clientId); 
        }
        const iWindow = (iframe as HTMLIFrameElement).contentWindow!;
        let msgToPost= JSON.stringify(azuretokenResp);
       
        if(msgToPost!=null && msgToPost!='')
           console.log('Azure token response exists');
        else
          console.log('Azure token response not exists');

        //let domainUrl=getDomainUrl(callbackUrl);
        

        this.displaySpinner();
        /* iWindow.addEventListener("load", function() {
          console.log("Finish");
          this.alert('iframe loaded');
         });
         */
        if(iframe!=null && msgToPost!=null && msgToPost!='')
        {
            this.clearLocalStorage();
            setTimeout(function () {
              //iWindow.postMessage(msgToPost, callbackUrl);
              iWindow.postMessage(msgToPost, '*');
              //alert(callbackUrl);
              console.log('callbackUr is ' + callbackUrl);
              
              window.location.href=callbackUrl; 
          }, 1000);
        }
        else
        {
          let errorDesc='';
          if(iframe ==null)
          {
            errorDesc=ErrorReason.COULD_NOT_ABLE_TO_SEND_MESSAGE;
            
            this.redirectToClientAppLoginWhenErrorOccured(errorDesc);
            
          }
          else if(msgToPost==null || msgToPost=='')
          {
            errorDesc=ErrorReason.AZURE_TOKEN_EMPTY_RESPONSE_BEFORE_SENDING_MESSAGE;
          }

        }
        
       
 
}

clearLocalStorage()
{
  localStorage.clear();
}

 setCookie(name: string, value: string, expireDays: number, path: string = '') {
  let d:Date = new Date();
  d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
  let expires:string = `expires=${d.toUTCString()}`;
  let cpath:string = path ? `; path=${path}` : '';
  document.cookie = `${name}=${value}; ${expires}${cpath}`;
}

private getCookie(name: string) {
  let ca: Array<string> = document.cookie.split(';');
  let caLen: number = ca.length;
  let cookieName = `${name}=`;
  let c: string;

  for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
          return c.substring(cookieName.length, c.length);
      }
  }
  return '';
}

private deleteCookie(name:string) {
  this.setCookie(name, '', -1);
}


}    



function getDomainUrl(callbackUrl: any)
{
  var str = callbackUrl , 
    delimiter = '/',
    start = 3,
    tokens = str.split(delimiter).slice(start),
    result = tokens.join(delimiter); 
    
console.log(result)

// To get the substring BEFORE the nth occurence
var tokens2 = str.split(delimiter).slice(0, start),
    result2 = tokens2.join(delimiter); // this

console.log(result2)
return result2
}

/* function getRefreshTokenJsonFromLocalStorage() {

  var values = [],
      keys = Object.keys(localStorage),
      i = keys.length;
  var localStorageItem=''
  while ( i-- ) {
     if(keys[i].indexOf('login.windows.net-refreshtoken')!=-1)
     {
        localStorageItem=localStorage.getItem(keys[i])!;
        break;
     }
     
  }
  let jsonObj: any = JSON.parse(localStorageItem); 
  return jsonObj;
} */

function removeIframeElement(rpostClientId:string)
{
  document.getElementById('receiverSOS1_'+rpostClientId)?.remove();
}

function detectPopupBlocker()
    {
    
    var isPopupblocker=false;
    var windowUrl = 'about:blank';
    var windowId = 'TestPopup_' + new Date().getTime();
    var windowFeatures = 'left=0%,top=0%,width=0.1%,height=0.1%';
    var windowRef = window.open(windowUrl, windowId, windowFeatures);

    if ( !windowRef )
    {
      //alert('A popup blocker was detected. Please turn it off to use this application.');
      //return false;
      $('#pnlPopupblockerMsg').css("display","block");
      isPopupblocker=true;
    }
    else
    {
      // No popup blocker was detected...
      windowRef.close();
      isPopupblocker=false;
      $('#pnlPopupblockerMsg').css("display","none");
      //document.getElementById('pageContent').style.display = 'block';
    }

    return isPopupblocker;
    }

    function postMessageToClientAppIfUserAlreadyLoggedin(clientId:string,callbackUrl:string,azuretokenResp:string)
    {
      alert('postMessageToClientApp');
      //const iframeWin = document.getElementById('h5-iframe').contentWindow;
      const iframe = document.getElementById("receiverSOS1_"+clientId); 
        //const iframe = document.getElementById("receiverIframe");
        console.log(iframe);
        //let azuretokenResp= this.azureTokenResponseGv;
        var msgToPost= JSON.stringify(azuretokenResp);
        //alert(iframe)
        if (iframe == null) { return; }
        const iWindow = (iframe as HTMLIFrameElement).contentWindow!;
      window.addEventListener(
        'message',
        (e) => {
          const { data } = e;
          console.log('receive page load', data);
          if (data.pageLoaded) {
            /* iframeWin.postMessage("hello", '*'); */
           // setTimeout(function () {
              iWindow.postMessage("rrrrr", '*');
              
              /* if(callbackUrl.indexOf('?IsAzureSSO=Y')==-1)
                  callbackUrl=callbackUrl+"?IsAzureSSO=Y"; */
                  //alert('callbackUrl'+callbackUrl);
              window.location.href=callbackUrl; 
          //}, 1000);
              
          }
          else{
            alert('iframe page is not loaded');
            setTimeout(function () {
              iWindow.postMessage('not', '*');
              
              /* if(callbackUrl.indexOf('?IsAzureSSO=Y')==-1)
                  callbackUrl=callbackUrl+"?IsAzureSSO=Y"; */
                  //alert('callbackUrl'+callbackUrl);
              window.location.href=callbackUrl; 
          }, 1000);
          }
        },
        false,
      );
    }

function displaySpinner() {
  throw new Error('Function not implemented.');
}

function handleGoogleToken(response:any)
{
  console.log(response);
  alert(response);
  //this.getGoogleTokenResponseAndRedirectToClientApplication();
}

function handleCredentialResponse() {
  // decodeJwtResponse() is a custom function defined by you
  // to decode the credential response.
  //const responsePayload = decodeJwtResponse(response.credential);

  /* console.log("ID: " + responsePayload.sub);
  console.log('Full Name: ' + responsePayload.name);
  console.log('Given Name: ' + responsePayload.given_name);
  console.log('Family Name: ' + responsePayload.family_name);
  console.log("Image URL: " + responsePayload.picture);
  console.log("Email: " + responsePayload.email); */
  alert('google login');
}

/* function trackGoogleLoginPopupClose()
{
  window.onload = function () {
    google.accounts.id.initialize({

      // Ref: https://developers.google.com/identity/gsi/web/reference/js-reference#IdConfiguration
    client_id: environment.googleSSOClientId,
    //callback: this.handleCredentialResponse.bind(this), // Whatever function you want to trigger...
    auto_select: false,
    ux_mode: 'popup',
    cancel_on_tap_outside: false
  });
    google.accounts.id.prompt((notification) => {
      if (notification.getDismissedReason()=='cancel_called') {
        // continue with another identity provider.
        alert('cancel');
      }
    });
  };
} */










